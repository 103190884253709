import { FC } from 'react';
import styled from 'styled-components';

import ArrowIcon from '../images/svgs/arrow-right.svg';
import SimpleLink, { LinkProps } from './SimpleLink';

let ArrowedLink: FC<LinkProps> = (props) => {
  return (
    <SimpleLink {...props}>
      {props.children}
      <ArrowIcon />
    </SimpleLink>
  );
};

ArrowedLink = styled(ArrowedLink)`
  text-decoration: none;
  color: ${({ theme }) => theme.text.primary};
  display: flex;
  align-items: center;

  svg {
    fill: ${({ theme }) => theme.text.primary};
    margin-left: var(--spacing-2);
  }
`;
export default ArrowedLink;
