import { FC } from 'react';
import { Col } from 'react-awesome-styled-grid';
import styled from 'styled-components';

import deviceBreakPoints from '../styles/breakpoints';
import { renderIcon } from '../utils/icons';
import ArrowedLink from './ArrowedLink';
import Card from './Card';
import { SectionRow } from './Rows';

interface CardData {
  title: string;
  description: string;
  icon: string;
  link: {
    text: string;
    url: string;
  };
}

let CardsRow: FC<{ cards: CardData[]; className?: string }> = ({ cards, className }) => (
  <SectionRow className={`scrollable full-width-mobile ${className}`}>
    {cards.map(({ title, description, icon, link }) => (
      <ColStyled key={title} xs={3} lg={4}>
        <CardStyled footer={<ArrowedLink to={link.url}>{link.text}</ArrowedLink>}>
          {renderIcon(icon)}
          <CardTitle>{title}</CardTitle>
          <CardDescription>{description}</CardDescription>
        </CardStyled>
      </ColStyled>
    ))}
  </SectionRow>
);

CardsRow = styled(CardsRow)`
  padding-top: 5px;
  padding-bottom: 5px;
`;

const ColStyled = styled(Col)`
  @media ${deviceBreakPoints.tablet} {
    min-width: auto;
  }

  @media ${deviceBreakPoints.mobile} {
    min-width: 75%;
  }
`;

const CardStyled = styled(Card)`
  min-height: 250px;
  height: 100%;
`;

const CardTitle = styled.div`
  color: ${({ theme }) => theme.text.secondary};
  margin-top: var(--spacing-2);
`;

const CardDescription = styled.div`
  margin-top: var(--spacing-2);
`;

export default CardsRow;
