import { graphql, useStaticQuery } from 'gatsby';
import { FC } from 'react';
import { Col, Row } from 'react-awesome-styled-grid';
import styled from 'styled-components';

import CaptionedTitle from '../CaptionedTitle';
import CardsRow from '../CardsRow';
import Markdown from '../Markdown';
import PageHeader from '../PageHeader';
import { BlockRow, SectionInnerRow, SectionRow } from '../Rows';

const Header: FC = () => {
  const { data } = useStaticQuery(query);
  const {
    header: { title, caption, description },
    cardsSection: { cards },
    policySection
  } = data.edges[0].node;

  return (
    <PageHeader>
      <Row>
        <Col>
          <CaptionedTitle as="h2" caption={caption}>
            {title}
          </CaptionedTitle>
          <BlockRow>
            <Col lg={6}>
              <Markdown content={description} />
            </Col>
          </BlockRow>
          <SectionInnerRow>
            <Col>
              <CardsRow cards={cards} />
            </Col>
          </SectionInnerRow>
        </Col>
      </Row>
      <SectionRow>
        <Col>
          <SectionInnerRow>
            <Col>
              <CaptionedTitle as="h2" styledAs="h3">
                {policySection.title}
              </CaptionedTitle>
            </Col>
          </SectionInnerRow>
          <SectionInnerRow justify="center">
            <Col lg={8}>
              <MarkdownStyled content={policySection.content} />
            </Col>
          </SectionInnerRow>
        </Col>
      </SectionRow>
    </PageHeader>
  );
};

const MarkdownStyled = styled(Markdown)`
  h2 {
    font-size: var(--font-size-4);
    font-weight: var(--font-weight-regular);
    margin-bottom: var(--spacing-5);

    &:not(:first-child) {
      margin-top: var(--spacing-5);
    }
  }
`;

const query = graphql`
  query {
    data: allSupportPageYaml {
      edges {
        node {
          header {
            title
            caption
            description
          }
          cardsSection {
            cards {
              title
              description
              icon
              link {
                text
                url
              }
            }
          }
          policySection {
            title
            content
          }
        }
      }
    }
  }
`;

export default Header;
