import { FC } from 'react';
import styled from 'styled-components';

import BtnBG from '../images/text-bg.jpg';
import BtnBorderBg from '../images/text-bg-inverted.jpg';

let Card: FC<{ footer?: JSX.Element; className?: string }> = ({ className, children, footer }) => {
  return (
    <div className={className}>
      <CardContents>
        <div>{children}</div>
        <Footer>{footer}</Footer>
      </CardContents>
    </div>
  );
};

Card = styled(Card)`
  background-image: url(${BtnBG});
  height: auto;
  background-size: 100% 100%;
  position: relative;

  &:hover {
    > div {
      transform: translate(0);
    }
  }
`;

const CardContents = styled.div`
  padding: var(--spacing-2);
  border: 1px solid transparent;
  border-image: url(${BtnBorderBg}) 1 stretch;
  background-color: var(--color-white);
  transition: transform 0.15s ease-in-out;
  transform: translate(-5px, -5px);
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Footer = styled.div`
  margin-top: var(--spacing-2);
`;
export default Card;
